import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loading from './Loading';

class SkipSSRWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mounted: false,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      mounted: true,
    });
  }

  render() {
    if (!this.state.mounted) {
      return <Loading />;
    }

    return this.props.children;
  }
}

SkipSSRWrapper.propTypes = {
  children: PropTypes.node,
};

const skipSSR = WrappedComponent => props => (
  <SkipSSRWrapper>
    <WrappedComponent {...props} />
  </SkipSSRWrapper>
);

export default skipSSR;
