import React from 'react';
import Helmet from 'react-helmet';
import wrapInSection from '../components/wrapInSection';
import Image from '../components/Image';
import SignupMini, { signupFields } from '../components/SignupMini';
import wrapInLayout from '../components/wrapInLayout';

const SberbankSpecialOffer = () => (
  <div className="page-wrapper">
    <Helmet title="КЭСПА – Специальное предложение для сотрудников Сбербанка" />
    <h1
      style={{
        marginTop: 0,
      }}
    >
      Специальное предложение <br /> для сотрудников{' '}
      <span
        style={{
          color: '#2C9A3F',
        }}
      >
        Сбербанка
      </span>
    </h1>
    <div className="row">
      <div className="col-md-4 col-sm-offset-1">
        <p>
          Скидка{' '}
          <span
            style={{
              color: '#2C9A3F',
              fontWeight: 'bold',
            }}
          >
            15%
          </span>{' '}
          на оплату любых занятий в школе английского языка КЭСПА.
        </p>

        <SignupMini
          signupFields={[
            ...signupFields,
            {
              name: 'whereFrom',
              label: '',
              type: 'hidden',
            },
          ]}
          initialValues={{
            lastName: '%',
            whereFrom: 'Программа лояльности Сбербанка "ДРУГ"',
          }}
        />
      </div>
      <div className="col-md-6 col-sm-offset-1">
        <Image
          src="kespa_sber_pic.png"
          alt="Специальное предложение для сотрудников Сбербанка!"
        />
      </div>
    </div>
  </div>
);

export default wrapInLayout(wrapInSection(SberbankSpecialOffer));
