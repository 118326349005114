import React from 'react';
import { user } from '@kespa/validators';
import Form from './Form';
import skipSSR from './skipSSR';
import { Link } from 'gatsby';

export const signupFields = [
  {
    name: 'firstName',
    label: 'Имя и фамилия',
  },
  {
    name: 'email',
    label: 'Почта',
  },
  {
    name: 'phone',
    label: 'Телефон',
    type: 'tel',
  },
  {
    name: 'skype',
    label: 'Skype',
    type: 'hidden',
  },
];

const signupSuccessMessage = () => (
  <div className="confirmation-message">
    <p className="fa-wrapper">
      <i className="fa fa-check" />
    </p>
    <p>
      Заявка получена! Мы свяжемся с&nbsp;вами по указанному телефону
      и&nbsp;согласуем время пробного урока.
    </p>
  </div>
);

const Signup = ({ signupFields }) => {
  React.useEffect(() => {
    try {
      const COOKIE_NAME = 'kespa-signup-query';
      // const hasCookie = document.cookie
      //   .split(';')
      //   .some(item => item.trim().startsWith(`${COOKIE_NAME}=`));

      const search = window.location.search.slice(1);

      // if (
      // search !== ''
      //&& !hasCookie
      //replacing cookie every time
      // ) {
      const date = new Date();
      date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
      const dateString = date.toGMTString();
      const cookieValue = `${COOKIE_NAME}=${encodeURIComponent(
        search
      )}; path=/; expires=${dateString}`;

      document.cookie = cookieValue;
      // }

      document.cookie = `kespa-referer=${document.referrer}`;
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
  }, []);

  return (
    <div className="signup-mini">
      <Form
        submitUrl="/auth/signup"
        fields={signupFields}
        form="signup"
        validate={user.validate}
        successMessage={signupSuccessMessage}
        buttonText="Записаться на вводный урок"
        onSubmitSuccess={() => {
          try {
            window.dataLayer.push({
              event: 'SubmitApplication',
            });
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
          }
        }}
      />

      <div className="signup-privacy-comment">
        Нажимая, вы принимаете условия <a href="" />
        <Link to={'/privacy-policy'}>
          Соглашения на обработку персональных данных
        </Link>
        .
      </div>
    </div>
  );
};

Signup.defaultProps = {
  signupFields,
};

export default skipSSR(Signup);
